/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_unjNbpAxL",
    "aws_user_pools_web_client_id": "1b5tk8gl842gptvdcrdj2cess2",
    "oauth": {}
};


export default awsmobile;
