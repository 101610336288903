

























































































































































































import Vue from 'vue'
import axios from 'axios'

const dt = new Date()
dt.setDate(dt.getDate() - 14)

interface Servers {
  headers: any[];
  stores: any[];
  unpaidRecords: any[];
  currentRecord: any;
  search: string;
  isLoading: boolean;
  page: number;
  itemsPerPage: number;
  fixed: boolean;
  date: string;
  startPicker: boolean;
  endPicker: boolean;
  formData: any;
  dialog: boolean;
}

export default Vue.extend({
  name: 'HelloWorld',
  data (): Servers {
    return {
      headers: [
        { text: '物件名', value: 'store_name' },
        { text: '車両情報', value: 'place' },
        { text: '入庫日時', value: 'entrant_time' },
        { text: '出庫日時', value: 'exit_time' },
        { text: '請求金額', value: 'account_total' },
        { text: '支払い金額', value: 'get_price' },
        { text: '車両画像', value: 'image_url' },
        { text: '', value: 'detail' }
      ],
      stores: [
        { text: 'ルネサンス白井店', value: 'renaissanceshiraiten' },
        { text: 'セブンイレブン足立竹の塚店', value: '711adachitakenodukaekiminamiten' }
      ],
      unpaidRecords: [],
      currentRecord: {},
      search: '',
      isLoading: true,
      page: 1,
      itemsPerPage: 100,
      fixed: true,
      date: new Date().toISOString().substr(0, 10),
      startPicker: false,
      endPicker: false,
      formData: {
        store: '',
        startDate: dt.toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        region: '',
        small_number: '',
        hiragana: '',
        large_number: ''
      },
      dialog: false
    }
  },
  filters: {
    separateDigit: function (value) {
      if (value === 0) return 0
      if (!value) return ''
      value = value.toLocaleString()
      return value
    }
  },
  computed: {
    setPageLength ():number {
      const result = Math.floor(this.unpaidRecords.length / this.itemsPerPage)
      console.log(result)
      const pageCount = this.unpaidRecords.length % this.page === 0 ? result : result + 1
      return pageCount
    }
  },
  methods: {
    async getUnpaidInfo (): Promise<void> {
      console.log('request start')
      console.log(this.formData.startDate)
      // this.loading = true
      // const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_servers_with_parking_fee_list`;
      const url = 'https://rr43v5v392.execute-api.ap-northeast-1.amazonaws.com/dev/unpaid_records/get'
      const params = {
        store: this.formData.store,
        startDate: this.formData.startDate,
        endDate: this.formData.endDate,
        region: this.formData.region,
        small_number: this.formData.small_number,
        hiragana: this.formData.hiragana,
        large_number: this.formData.large_number
      }
      return await axios
        .get(url, { params: params })
        .then(res => {
          console.log(res)
          this.unpaidRecords = res.data
          // this.servers = res.data;
          // this.loading = false
        })
        .catch(error => {
          console.log(error)
          alert('サーバー情報の取得に失敗しました！')
          // this.loading = false
        })
    },
    openDialog (record) {
      console.log(record)
      this.currentRecord = record
      this.dialog = true
    },
    clearCarInfo (): void {
      this.formData.region = ''
      this.formData.small_number = ''
      this.formData.hiragana = ''
      this.formData.large_number = ''
    }
  },
  mounted () {
    this.getUnpaidInfo()
  }
})
