<template>
  <div id="app">
    <amplify-authenticator v-if= "!signedIn"></amplify-authenticator>
    <v-app v-if="signedIn && user">
      <SideMenu class="sidebar-area"></SideMenu>
      <div class="wrapper">
        <router-view />
        <amplify-sign-out></amplify-sign-out>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue'
import SideMenu from './components/layouts/SideMenu.vue'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth } from '@aws-amplify/auth'
import { AmplifyEventBus } from 'aws-amplify-vue'

export default Vue.extend({
  name: 'App',
  components: {
    SideMenu
  },
  data () {
    return {
      user: undefined,
      signedIn: undefined
    }
  },
  async beforeCreate () {
    try {
      this.user = await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }
    // 認証ステータスが変わった時に呼び出されるイベントを登録
    AmplifyEventBus.$on('authState', async info => {
      if (info === 'signedIn') {
        this.signedIn = true
        this.user = await Auth.currentAuthenticatedUser()
      } else {
        this.signedIn = false
        this.user = undefined
      }
    })
  },
  beforeDestroy () {
    return onAuthUIStateChange
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}

.sidebar-area {
  position: fixed !important;
  left: 0;
  height: 100%;
  border-radius: 0px !important;
}

.wrapper { width: calc(100% - 300px); margin: 0 0 0 270px; }

th { text-align: left; }

.search-col { margin-left: auto; }
</style>
