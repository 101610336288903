<template>
    <v-card class="mx-auto sidemenu" width="256">
      <v-navigation-drawer class="sidebar-bg" dark permanent>
        <h1>Quick <strong>Park</strong></h1>
        <v-list>
          <v-list-item v-for="item in items" :key="item.title" link>
            <router-link v-bind:to="item.url" class="menu-link">
              <v-list-item-icon class="icon-margin">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SideMenu',
  data () {
    return {
      items: [
        { title: '未精算車両一覧', icon: 'mdi-view-dashboard', url: '/' },
        { title: '精算済み車両一覧', icon: 'mdi-database-search', url: '/aaa' }
      ]
    }
  }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;500&display=swap');

/* .sidemenu { font-family: 'M PLUS Rounded 1c', sans-serif !important; } */

h1 { font-size: 2rem; color: whitesmoke; text-align: center; margin: 20px 10px 30px; border-bottom: solid 2px white; font-family: 'Kiwi Maru', serif !important; }

strong { color: #f9bc60;}

.sidebar-bg { background-image: linear-gradient( 109.6deg,  rgba(5,85,84,1) 11.2%, rgba(64,224,208,1) 91.1% ) !important;}

.menu-link { display: flex; text-decoration: none; color: #fffffe; font-weight: bold; }

.icon-margin { margin-right: 10px !important; }

.sidemenu a { width: 100%; padding: 0 20px; border-radius: 5px;}

.sidemenu i { color: #fffffe !important; font-size: 1.1rem !important;}

.sidemenu div { font-size: 0.9rem; font-family: 'Kiwi Maru', serif !important;}

.router-link-active { background-color: #f9bc60; color: #001e1d !important;}

.router-link-active i { color: #001e1d !important;}

h1 { position: relative; padding: 0.25em 1em; border-top: solid 2px white; border-bottom: solid 2px white; }

h1:before, h1:after {
  content: '';
  position: absolute;
  top: -7px;
  width: 2px;
  height: -webkit-calc(100% + 14px);
  height: calc(100% + 14px);
  background-color: white;
}

h1:before { left: 7px; }

h1:after { right: 7px; }

.theme--dark.v-list-item:hover::before { opacity: 0; }

.theme--dark.v-list-item a:hover { opacity: 0.8; }

</style>
